import Vue from "vue";

/*TITLE*/
document.title = "CAN ESCANDELL | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "CAN ESCANDELL";
Vue.prototype.$subtitle = "ES CANAR 11 -13 ESQ. BESORA | IBIZA";

/*INTRO*/
Vue.prototype.$promoter = "CAN ESCANDELL";
Vue.prototype.$introSubtitle = "ES CANAR 11 -13 ESQ. BESORA | IBIZA";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091522.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091522.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091522.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091522.jpeg";
Vue.prototype.$image_room = "0-bedroom--20221124091522.jpeg";
Vue.prototype.$image_facade = "0-facade--20221124091522.jpeg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091522.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091522.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091522.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091522.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "0-bedroom--20221124091522.jpeg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "0-facade--20221124091522.jpeg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/canescandell/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=es%20canar%2011%2013%20esq%20besora%20islas%20baleares%2007849",
    text: "ES CANAR 11 -13 ESQ. BESORA, Islas Baleares, 07849",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=es%20canar%2011%2013%20esq%20besora%20islas%20baleares%2007849",
    text: "ES CANAR 11 -13 ESQ. BESORA, Islas Baleares, 07849",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:971311213",
    text: "971311213",
  },
  {
    icon: "mdi-email",
    link: "mailto:inmo@grupohnosparrot.com",
    text: "inmo@grupohnosparrot.com",
  },
];
